/* eslint-disable max-lines */

import { ChipPropsColorOverrides } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import { OverridableStringUnion } from "@mui/types"

declare module "@mui/material/styles/overrides" {}

// Update this object to include an new brand color option
export enum EverDrivenCustomPalette {
  pastelDarkBlue = "#26265E20",
  pastelBlue = "#3673B030",
  pastelLightBlue = "#48A5DE33",
  pastelGreen = "#367E3733",
  pastelYellow = "#FF9A3A30",
  pastelFuchsia = "#C2426933",
  pastelLightDark = "#33333314"
}

declare module "@mui/material/styles" {
  interface Palette {
    pastelBlue: Palette["primary"]
    pastelLightBlue: Palette["primary"]
    pastelDarkBlue: Palette["primary"]
    pastelFuchsia: Palette["primary"]
    pastelGreen: Palette["primary"]
    pastelYellow: Palette["primary"]
    pastelLightDark: Palette["primary"]
  }

  interface PaletteOptions {
    pastelBlue?: PaletteOptions["primary"]
    pastelLightBlue?: PaletteOptions["primary"]
    pastelDarkBlue?: PaletteOptions["primary"]
    pastelFuchsia?: PaletteOptions["primary"]
    pastelGreen?: PaletteOptions["primary"]
    pastelYellow?: PaletteOptions["primary"]
    pastelLightDark?: PaletteOptions["primary"]
  }
}

// Update the Chips's color options
declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    pastelBlue: true
    pastelLightBlue: true
    pastelDarkBlue: true
    pastelFuchsia: true
    pastelGreen: true
    pastelYellow: true
    pastelLightDark: true
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    link1: React.CSSProperties
    link2: React.CSSProperties
    body3: React.CSSProperties
    custom: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    link1?: React.CSSProperties
    link2?: React.CSSProperties
    body3?: React.CSSProperties
    custom?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    link1: true
    link2: true
    body3: true
    custom: true
  }
}

export type ColorChipType = OverridableStringUnion<
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning",
  ChipPropsColorOverrides
>

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: "#26265E",
      light: "#51517E",
      dark: "#1A1A41"
    },
    secondary: {
      main: "#3673B0",
      light: "#5E8FBF",
      dark: "#25507B"
    },
    error: {
      main: "#C24269",
      light: "#CE6787",
      dark: "#872E49"
    },
    warning: {
      main: "#FF9A3A",
      light: "#FFF4E5",
      dark: "#B26B28"
    },
    success: {
      main: "#367E37",
      light: "#5E975F",
      dark: "#255826"
    },
    info: {
      main: "#3673B0",
      light: "#5E8FBF",
      dark: "#25507B"
    },
    common: {
      black: "#333333"
    },
    background: {
      paper: "#FFFFFF"
    },
    pastelBlue: {
      main: EverDrivenCustomPalette.pastelBlue,
      contrastText: "#333333"
    },
    pastelLightBlue: {
      main: EverDrivenCustomPalette.pastelLightBlue,
      contrastText: "#333333"
    },
    pastelDarkBlue: {
      main: EverDrivenCustomPalette.pastelDarkBlue,
      contrastText: "#333333"
    },
    pastelFuchsia: {
      main: EverDrivenCustomPalette.pastelFuchsia,
      contrastText: "#333333"
    },
    pastelYellow: {
      main: EverDrivenCustomPalette.pastelYellow,
      contrastText: "#333333"
    },
    pastelGreen: {
      main: EverDrivenCustomPalette.pastelGreen,
      contrastText: "#333333"
    },
    pastelLightDark: {
      main: EverDrivenCustomPalette.pastelLightDark,
      contrastText: "#333333"
    },
    text: {
      primary: "#333333",
      secondary: "#6D6D6D"
    }
  },
  shape: {
    borderRadius: 5
  },
  typography: {
    fontFamily: [
      "MuseoSans500",
      "MuseoSans700",
      "Helvetica Neue",
      "Roboto",
      "Arial",
      "sans-serif"
    ].join(","),
    fontWeightBold: 700,
    link1: {
      color: "#3673B0",
      fontSize: 16,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      letterSpacing: 0.25,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal",
      cursor: "pointer"
    },
    link2: {
      color: "#3673B0",
      fontSize: 14,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      letterSpacing: 0.25,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal",
      cursor: "pointer"
    },
    button: {
      color: "#333333",
      fontSize: 16,
      fontFamily: "MuseoSans500",
      fontWeight: "700",
      textTransform: "capitalize",
      letterSpacing: 0.04,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    body1: {
      color: "#333333",
      fontSize: 16,
      fontFamily: "MuseoSans500",
      fontWeight: "500",
      letterSpacing: 0.15,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    body2: {
      color: "#333333",
      fontSize: 14,
      fontFamily: "MuseoSans500",
      fontWeight: "500",
      letterSpacing: 0.15,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    body3: {
      color: "#333333",
      fontSize: 13,
      fontFamily: "MuseoSans500",
      fontWeight: "500",
      letterSpacing: 0.25,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    subtitle1: {
      color: "#333333",
      fontSize: 16,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      letterSpacing: 0.2,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    subtitle2: {
      color: "#333333",
      fontSize: 14,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      letterSpacing: 0.2,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    overline: {
      color: "#333333",
      fontSize: 14,
      fontFamily: "MuseoSans500",
      fontWeight: "500",
      textTransform: "uppercase",
      letterSpacing: 1.4,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    caption: {
      color: "#333333",
      fontSize: 13,
      fontFamily: "MuseoSans500",
      fontWeight: "500",
      letterSpacing: 0.4,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    h6: {
      color: "#333333",
      fontSize: 20,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      letterSpacing: 0.2,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    h5: {
      color: "#333333",
      fontSize: 24,
      fontFamily: "MuseoSans500",
      fontWeight: "500",
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    h4: {
      color: "#333333",
      fontSize: 24,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      letterSpacing: 0.25,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    h3: {
      color: "#333333",
      fontSize: 32,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    },
    h2: {
      color: "#333333",
      fontSize: 48,
      fontFamily: "MuseoSans500",
      fontWeight: "500",
      wordWrap: "break-word",
      lineHeight: "normal",
      letterSpacing: -0.2,
      fontStyle: "normal"
    },
    h1: {
      color: "#333333",
      fontSize: 64,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      wordWrap: "break-word",
      lineHeight: "normal",
      letterSpacing: -1.5,
      fontStyle: "normal"
    },
    custom: {
      color: "#333333",
      fontSize: 14,
      fontFamily: "MuseoSans700",
      fontWeight: "700",
      letterSpacing: 0.2,
      wordWrap: "break-word",
      lineHeight: "normal",
      fontStyle: "normal"
    }
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          gap: 4
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          gap: 8
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "flex-start",
          minHeight: "fit-content",
          transition: "margin 0.1s ease-out",
          "&.Mui-expanded": {
            minHeight: "fit-content",
            marginBottom: 24
          },
          padding: 0
        },
        expandIconWrapper: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0
          }
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none"
          },
          "&.Mui-expanded": {
            margin: 0
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: "1px solid #FFFFFF",
          boxSizing: "border-box"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: "fit-content",
          height: "fit-content",
          color: "inherit"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingLeft: 4,
          paddingRight: 4
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#5E8FBF",
          color: "#FFFFFF",
          fontSize: 13,
          fontFamily: "MuseoSans500",
          fontWeight: "500",
          wordWrap: "break-word",
          lineHeight: "normal",
          fontStyle: "normal"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontSize: 16,
          borderRadius: 100,
          height: 48,
          padding: "0 20px",
          borderWidth: 2,
          minWidth: 100,
          fontFamily: "MuseoSans700",
          fontWeight: "700",
          letterSpacing: 0.04,
          wordWrap: "break-word",
          lineHeight: "normal",
          fontStyle: "normal"
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontStyle: "normal",
          lineHeight: "normal",
          textTransform: "capitalize",
          color: "#3673B0",
          fontSize: 16,
          fontFamily: "MuseoSans500",
          fontWeight: "500",
          letterSpacing: 0.25,
          wordWrap: "break-word"
        }
      }
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: "small" },
          style: {
            width: 18,
            height: 18
          }
        },
        {
          props: { fontSize: "medium" },
          style: {
            width: 20,
            height: 20
          }
        }
      ]
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "&.MuiTablePagination-selectLabel": {
            color: "#33333399",
            fontSize: 13,
            fontFamily: "MuseoSans500",
            fontWeight: "500",
            letterSpacing: 0.4,
            wordWrap: "break-word",
            lineHeight: "inherit",
            fontStyle: "normal"
          },
          "&.MuiTablePagination-select": {
            color: "#333333",
            fontSize: 13,
            fontFamily: "MuseoSans500",
            fontWeight: "500",
            letterSpacing: 0.4,
            wordWrap: "break-word",
            lineHeight: "inherit",
            fontStyle: "normal"
          },
          "&.MuiTablePagination-displayedRows": {
            color: "#333333",
            fontSize: 13,
            fontFamily: "MuseoSans500",
            fontWeight: "500",
            letterSpacing: 0.4,
            wordWrap: "break-word",
            lineHeight: "inherit",
            fontStyle: "normal",
            marginTop: 0,
            marginBottom: 0
          },
          "&.MuiSelect-select": {
            paddingTop: 0,
            paddingBottom: 0
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#3673B0"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 156,
          "&.Mui-selected": {
            color: "#3673B0"
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true }
      },
      styleOverrides: {
        root: {
          // Outlined
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main"
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "secondary.main"
              }
            },
            "&:hover:not(.Mui-focused)": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main"
              }
            }
          },
          "& .MuiInputLabel-outlined": {
            color: "primary.main"
          },
          // Filled
          "& .MuiFilledInput-root": {
            "&:before": {
              borderColor: "primary.main"
            },
            "&:after": {
              borderColor: "primary.main"
            },
            ":hover:not(.Mui-focused)": {
              "&:before": {
                borderColor: "primary.main"
              }
            }
          },
          "& .MuiInputLabel-filled": {
            "&.Mui-focused": {
              color: "primary.main"
            }
          },
          // Standard
          "& .MuiInput-root": {
            "&:before": {
              borderColor: "primary.main"
            },
            "&:after": {
              borderColor: "primary.main"
            },
            ":hover:not(.Mui-focused)": {
              "&:before": {
                borderColor: "primary.main"
              }
            }
          }
        }
      }
    }
  }
})

export default theme
