import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon
} from "@mui/icons-material"
import {
  CssBaseline,
  MenuItem,
  SelectChangeEvent,
  Theme,
  useMediaQuery
} from "@mui/material"
import { Box } from "@mui/system"
import Auth from "auth/Auth"
import { useGetProviderNameQuery } from "generated/graphql"
import headerAdapter from "graphql/adapters/Header.adapter"
import useProviderVars from "graphql/defaultVariables/ProviderVariables"
import useLanguage from "hooks/useLanguage"
import useUserContext from "hooks/useUserContext"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import {
  IS_BROADCAST,
  IS_DEV_ENVIRONMENT,
  languages,
  ROUTES
} from "utils/constants"

import LeftMenu from "../LeftMenu"
import {
  Container,
  DisplayNameNotificationsContainer,
  DrawerContainer,
  DrawerDesktop,
  DrawerMobile,
  EverDrivenIcon,
  NameDisplay,
  NotificationIcon,
  ProviderName,
  RightBoxLink,
  RightBoxLinkBox,
  RightBoxLinkBoxSignOut,
  StyledAppBar,
  StyledIconButton,
  StyledList,
  StyledListItemButton,
  StyledListItemText,
  StyledLogoutIcon,
  StyledNavLink,
  StyledOpenInNewIcon,
  StyledResourcesNavLink,
  StyledSelect,
  StyledSignOutBox,
  StyledToolbar
} from "./Header.styles"
import { IHeader, ListLinkType, ScreenListType } from "./Header.types"

const Header: FC<IHeader> = ({
  openDrawer = true,
  setOpenDrawer = () => {}
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { language, setLanguage } = useLanguage()
  const { t } = useTranslation("global")
  const { pathname } = useLocation()
  const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const vars = useProviderVars()
  const providerAPI = useGetProviderNameQuery({
    ...vars
  })
  const { userIsAdmin, salesforceAuthenticated } = useUserContext()

  const screens = [
    {
      displayName: t("component.Header.schedule"),
      route: ROUTES.SCHEDULE,
      testId: "NavigationSchedule"
    },
    {
      displayName: t("component.Header.drivers"),
      route: ROUTES.DRIVERS,
      testID: "NavigationDrivers"
    }
  ] as ScreenListType[]

  if (userIsAdmin) {
    screens.push({
      displayName: t("component.Header.users"),
      route: ROUTES.USERS,
      testID: "NavigationUsers"
    })
  }

  const linkList = [
    {
      displayName: t("component.Header.support"),
      href: "https://rrts1614707543.atlassian.net/servicedesk/customer/portal/3/group/13/create/55",
      external: true,
      testID: "LinkSupport"
    }
  ] as ListLinkType[]

  if (salesforceAuthenticated) {
    linkList.unshift({
      displayName: t("component.Header.compliance"),
      href: `${process.env.REACT_APP_COMPLIANCE_BASE_URL}/${process.env.REACT_APP_COMPLIANCE_ROUTE}/?auth=${process.env.REACT_APP_COMPLIANCE_AUTH_SUFFIX}`,
      external: true,
      testID: "LinkCompliance"
    })
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setLanguage(event.target.value as string)
  }

  const handleDrawerToggle = () => setOpenDrawer(!openDrawer)

  const handleNotificationsClick = () => navigate(ROUTES.NOTIFICATIONS)

  const handleLinkClick = () => {
    if (isDownMd) setOpenDrawer(false)
  }

  const { providerName } = headerAdapter({ dataAPI: providerAPI.data })

  useEffect(() => {
    if (isDownMd) setOpenDrawer(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownMd])

  const hasParams = searchParams.toString() !== ""

  const drawer = (
    <>
      <StyledToolbar />
      <StyledList>
        {screens.map(({ displayName, route, testID }, screenIndex) => {
          const navLinkKey = `link-key-${screenIndex}-${displayName}`
          const isActive = pathname.includes(route)

          return (
            <StyledNavLink
              data-testid={testID}
              key={navLinkKey}
              reloadDocument={isActive && hasParams}
              to={route}
              onClick={handleLinkClick}
            >
              <StyledListItemButton isActive={isActive}>
                <StyledListItemText disableTypography>
                  {displayName}
                </StyledListItemText>
              </StyledListItemButton>
            </StyledNavLink>
          )
        })}

        <StyledResourcesNavLink
          data-testid="ResourcesLink"
          to={ROUTES.RESOURCES}
          onClick={handleLinkClick}
        >
          <StyledListItemButton>
            <StyledListItemText disableTypography>
              {t("component.Header.resources")}
            </StyledListItemText>
          </StyledListItemButton>
        </StyledResourcesNavLink>

        <Box data-testid="BoxNavigation">
          {linkList.map(
            ({ displayName, href, external, download }, linksIndex) => {
              const linkKey = `link-key-${displayName}-${linksIndex}`

              return (
                <RightBoxLink
                  download={download}
                  href={href}
                  key={linkKey}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="none"
                  variant="button"
                >
                  <StyledListItemButton key={linkKey}>
                    <StyledListItemText disableTypography>
                      <RightBoxLinkBox>
                        {displayName} {external && <StyledOpenInNewIcon />}
                      </RightBoxLinkBox>
                    </StyledListItemText>
                  </StyledListItemButton>
                </RightBoxLink>
              )
            }
          )}
          <StyledSelect
            data-testid="language"
            label=""
            value={language}
            onChange={handleChange}
          >
            {languages.map((languageItem) => (
              <MenuItem key={languageItem.code} value={languageItem.code}>
                {languageItem.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>
        <StyledSignOutBox>
          <StyledListItemButton
            data-testid="SignOutLink"
            onClick={() => {
              Auth.signOut()
            }}
          >
            <StyledListItemText disableTypography>
              <RightBoxLinkBoxSignOut>
                {t("component.Header.signOut")} <StyledLogoutIcon />
              </RightBoxLinkBoxSignOut>
            </StyledListItemText>
          </StyledListItemButton>
        </StyledSignOutBox>
      </StyledList>
    </>
  )

  return (
    <Container>
      <CssBaseline />
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <DisplayNameNotificationsContainer data-testid="NotificationsContainer">
            <StyledIconButton
              aria-label={t("component.Header.openDrawer")}
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </StyledIconButton>
            <NameDisplay data-testid="providerName">
              <EverDrivenIcon
                data-testid="EverDrivenLogo"
                title="EverDrivenLogo"
              />
              <ProviderName noWrap variant="body1">
                {providerName}
              </ProviderName>
            </NameDisplay>
            {IS_DEV_ENVIRONMENT && IS_BROADCAST && (
              <NotificationIcon
                aria-label={t("component.Header.openDrawer")}
                color="inherit"
                edge="start"
                onClick={handleNotificationsClick}
              >
                <NotificationsIcon />
              </NotificationIcon>
            )}
          </DisplayNameNotificationsContainer>
          <LeftMenu linkList={linkList} />
        </StyledToolbar>
      </StyledAppBar>

      <DrawerContainer aria-label="navigation" component="nav">
        <DrawerDesktop
          data-testid="DesktopNavigation"
          open={openDrawer}
          variant="persistent"
        >
          {drawer}
        </DrawerDesktop>
        <DrawerMobile
          data-testid="MobileNavigation"
          ModalProps={{
            keepMounted: true
          }}
          open={openDrawer}
          variant="temporary"
          onClose={handleDrawerToggle}
        >
          {drawer}
        </DrawerMobile>
      </DrawerContainer>
    </Container>
  )
}

export default Header
