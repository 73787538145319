import { FC, MutableRefObject, useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"

export interface IScrollToTop {
  children: React.ReactNode
  parentRef: MutableRefObject<HTMLElement | null>
}

const ScrollToTop: FC<IScrollToTop> = ({ children = [], parentRef }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    parentRef?.current?.scrollIntoView()
  }, [location, parentRef])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default ScrollToTop
