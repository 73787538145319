// Local Storage

import { ComboBoxType } from "components/shared/Table/FilterComponent/FilterComponent.types"
import {
  Order,
  PastOffersSortFieldsType,
  RowsPerPageType,
  SentOffersSortFieldsType,
  TableHeaderType
} from "components/shared/Table/Table.types"
import {
  DriverSortFieldsType,
  OfferSortFieldsType,
  RunSortFieldsType
} from "generated/graphql"
import { VehicleRunIdsType } from "screens/VehicleRun/VehicleRun.types"

export const PAGES_LOCAL_STORAGE = {
  ASSIGN_DRIVER: "ASSIGN_DRIVER",
  DRIVERS: "DRIVERS",
  DRIVERS_MONITORS: "DRIVERS_MONITORS",
  MONITORS: "MONITORS",
  SCHEDULE: "SCHEDULE",
  SCHEDULE_AT_RISK: "SCHEDULE_AT_RISK",
  DRIVER: "DRIVER",
  VEHICLES: "VEHICLES",
  APP: "APP",
  VEHICLE_RUN: "VEHICLE_RUN",
  OPEN_OFFERS_OPS: "OPEN_OFFERS_OPS",
  SENT_OFFERS_OPS: "SENT_OFFERS_OPS",
  OPEN_OFFERS_SP: "OPEN_OFFERS_SP",
  ACCEPTED_OFFERS_SP: "ACCEPTED_OFFERS_SP",
  APP_VERSION: "APP_VERSION",
  FIRST_LOGIN: "FIRST_LOGIN"
} as const

type AssignDriver = "ASSIGN_DRIVER"
type Drivers = "DRIVERS"
type DriversMonitors = "DRIVERS_MONITORS"
type Monitors = "MONITORS"
type Schedule = "SCHEDULE"
type ScheduleAtRisk = "SCHEDULE_AT_RISK"
type Driver = "DRIVER"
type Vehicles = "VEHICLES"
type App = "APP"
type VehicleRun = "VEHICLE_RUN"
type OpenOffersOPS = "OPEN_OFFERS_OPS"
type SentOffersOPS = "SENT_OFFERS_OPS"
type OpenOffersSP = "OPEN_OFFERS_SP"
type AcceptedOffersSP = "ACCEPTED_OFFERS_SP"

export type PagesLocalStorage =
  | AssignDriver
  | Drivers
  | DriversMonitors
  | Monitors
  | Schedule
  | Driver
  | Vehicles
  | App
  | VehicleRun
  | OpenOffersOPS
  | SentOffersOPS
  | OpenOffersSP
  | AcceptedOffersSP
  | ScheduleAtRisk

export const SETTINGS_LOCAL_STORAGE = {
  ROWS_PER_PAGE: "rowsPerPage",
  TABLE_HEADERS: "tableHeaders",
  VEHICLE_RUN_IDS: "vehicleRunIds",
  STATUS_FILTER: "statusFilter",
  ORDER_BY: "orderBy",
  ORDER: "order",
  PICKUP_STATUS_FILTER: "pickupFilterStatus",
  DROPOFF_STATUS_FILTER: "dropoffFilterStatus",
  OFFER_STATUS_FILTER: "offerStatusFilter"
} as const

export type PageLocalStorageType = {
  [pageKey in (typeof PAGES_LOCAL_STORAGE)[keyof typeof PAGES_LOCAL_STORAGE]]: {
    [settingKey in (typeof SETTINGS_LOCAL_STORAGE)[keyof typeof SETTINGS_LOCAL_STORAGE]]:
      | RowsPerPageType
      | TableHeaderType[]
      | VehicleRunIdsType[]
      | ComboBoxType[]
      | RunSortFieldsType
      | DriverSortFieldsType
      | Order
      | OfferSortFieldsType
      | SentOffersSortFieldsType
      | PastOffersSortFieldsType
  }
}

export const INITIAL_PAGE_SETTINGS = {} as PageLocalStorageType
