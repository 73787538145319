import "./index.css"
import "./i18n/config"

import { disableReactDevTools } from "@fvilers/disable-react-devtools"
import { LicenseInfo } from "@mui/x-license"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { sendToAnalytics } from "analytics/analytics"
import client from "graphql/queryClient"
import * as ReactDOMClient from "react-dom/client"
import ReactGA from "react-ga-neo"
import { clarity } from "react-microsoft-clarity"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import App from "./App"
import { UserContextProvider } from "./contexts/UserContext/UserContextProvider"
import reportWebVitals from "./reportWebVitals"
import { EMPTY_CLARITY, EMPTY_GA, IS_PROD_ENVIRONMENT } from "./utils/constants"

if (IS_PROD_ENVIRONMENT) {
  disableReactDevTools()
}

const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY ?? ""
LicenseInfo.setLicenseKey(muiLicenseKey)

const container = document.getElementById("root") as HTMLElement
const root = ReactDOMClient.createRoot(container)

const gaId = process.env.REACT_APP_GA_ID ?? EMPTY_GA
ReactGA.initialize(gaId)

const clarityId = process.env.REACT_APP_CLARITY_ID ?? EMPTY_CLARITY
if (clarityId !== EMPTY_CLARITY) {
  clarity.init(clarityId)
}

root.render(
  <BrowserRouter>
    <QueryClientProvider client={client}>
      <UserContextProvider>
        <Routes>
          <Route element={<App />} path="/*" />
        </Routes>
      </UserContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
)

reportWebVitals(sendToAnalytics)
